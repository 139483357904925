<template>
    <span v-if="profile.role != 'User'">
        <v-card class="mt-3 pa-3">
            <v-card-text>
                <v-data-table :headers="headers" :items="users" >
                    <template v-slot:item.actions="{ item }">
                        <span class="d-flex align-center justify-center">
                            <v-btn color="secondary" icon @click="openEditDialog(item)">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn color="warning" icon @click="openChangePasswordDialog(item)">
                                <v-icon>mdi-lock-alert-outline</v-icon>
                            </v-btn>
                            <v-btn color="error" icon @click="deleteProfile(item)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="profileEditDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Modifier le profile</v-card-title>
                <v-card-text>
                    <v-text-field label="Nom" v-model="profileEditLastame"></v-text-field>
                    <v-text-field label="Prenom" v-model="profileEditFirstame"></v-text-field>
                <v-select :items="rolesList" v-model="profileEditRole" label="Role" v-if="profile.role == 'SuperAdmin' || (profile.role == 'Admin' && ['User', 'Animator'].includes(profileEditRole))"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="profileEditDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="renameProfile">Modifier</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="changePasswordDialog" scrollable persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Changer le mot de passe</v-card-title>
                <v-card-text>
                    <v-text-field label="Nouveau mot de passe" type="password" v-model="passwordOne"></v-text-field>
                    <v-text-field label="Confirmer mot de passe" type="password" v-model="passwordTwo"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="changePasswordDialog = false">Annuler</v-btn>
                    <v-btn color="primary" text @click="changePassword">Modifier</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { getFunctions, httpsCallable } from 'firebase/functions'

import Profile from '@/classes/Profile.js'

export default {
    name: "Users",
    props: ["user", "profile"],
    data() {
        return {
            unsub : [],
            headers: [
                { text: 'Nom', value: 'lastname' },
                { text: 'Prenom', value: 'firstname' },
                { text: 'Email', value: 'email' },
                { text: 'Role', value: 'role' },
                { text: '', value: 'actions', sortable: false, align: 'right' },
            ],
            users: [],
            profileEditDialog: false,
            profileEditSelected: null,
            profileEditFirstame: '',
            profileEditLastame: '',
            profileEditRole: '',
            changePasswordDialog: false,
            passwordOne: '',
            passwordTwo: '',
        }
    },
    created() {
        this.unsub.push(Profile.listenAll((profiles) => {
            this.users = profiles
        }))
    },
    computed: {
        rolesList() {
            if(this.profile.role == 'SuperAdmin'){
                return ['User', 'Animator', 'Admin', 'SuperAdmin']
            }else if(this.profile.role == 'Admin'){
                return ['User', 'Animator']
            }
            return []
        }
    },
    methods: {
        openEditDialog(profile) {
            this.profileEditSelected = profile
            this.profileEditFirstame = profile.firstname
            this.profileEditLastame = profile.lastname
            this.profileEditRole = profile.role
            this.profileEditDialog = true
        },
        openChangePasswordDialog(profile){
            this.profileEditSelected = profile
            this.changePasswordDialog = true
            this.passwordOne = ''
            this.passwordTwo = ''
        },
        renameProfile() {
            this.profileEditSelected.firstname = this.profileEditFirstame
            this.profileEditSelected.lastname = this.profileEditLastame
            this.profileEditSelected.role = this.profileEditRole
            if(this.profileEditSelected.role != "User"){
                this.profileEditSelected.team = null
            }
            this.profileEditSelected.save()
            this.profileEditDialog = false
        },
        async changePassword(){
            if(this.passwordOne != this.passwordTwo){
                Swal.fire({
                    title: 'Erreur',
                    text: 'Les mots de passe ne correspondent pas',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                })
                return
            }
            let userId = this.profileEditSelected.uid
            let hash = btoa(this.passwordOne)
            let changePass = httpsCallable(getFunctions(), 'changePassword')
            let res = await changePass({
              userId : userId,
              hash : hash
            })
            Swal.fire({
                title: 'Succès',
                text: 'Le mot de passe a bien été changé',
                icon: 'success',
                confirmButtonText: 'Ok',
            })
            this.changePasswordDialog = false
        },
        deleteProfile(profile){
            Swal.fire({
                title: 'Etes vous sûr ?',
                text: 'Voulez vous vraiment supprimer cet utilisateur ?',
                icon: 'question',
                confirmButtonText: 'Confirmer',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    profile.delete()
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => {
            if(typeof unsub == "function"){
                unsub()
            }
        })
    },
}
</script>
